<template>
  <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal" tabindex="0" @keydown.esc="closeModal">
    <div class="modal-content">
      <h4 class="modal-title">
        Feedback
      </h4>
      <textarea v-model="feedbackText" placeholder="Enter your feedback here..." class="feedback-input"></textarea>
      <b-form-checkbox class="m-auto" style="width: fit-content; margin: unset !important;" v-model="hideCheckBox">
        Hide this question from app?
      </b-form-checkbox>


      <div class="modal-actions mt-3">
        <button @click="saveFeedback" class="save-button" :disabled="isLoading || !feedbackText.trim()">
          {{ isLoading ? "Saving..." : "Save" }}
        </button>
        <button type="button" @click="closeModal" class="btn btn-outline-secondary pt-1 pb-1">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      feedbackText: "",
      isLoading: false,
      hideCheckBox: false,
    };
  },


  watch: {

    feedbackText(txt) {

      // console.log('txt', txt)

    },
    hideCheckBox(val) {
      // console.log('val', val)
    }
  },



  methods: {

    async saveFeedback() {
      this.isLoading = true;
      try {
        const currentDate = new Date().toISOString().split('T')[0];
        const currentTime = new Date().toLocaleTimeString();

        const generateRandomID = () => '_' + Math.random().toString(36).substr(2, 9);
        const payload = {
          questionId: this.question._id,
          reply: [
            {
              messageId: generateRandomID(),
              reply: this.feedbackText,
              replyTime: currentTime,
              replyDate: currentDate,
              likes: [],
              disLikes: [],
              hideQuestion: this.hideCheckBox,
            },  
          ],
        };

        // console.log('payload', JSON.stringify(payload, null, 10))
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/feedback/saveFeedback`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_t")}`,
            },
          }
        );

        this.$emit("saveFeedbackResponse", response.data);
        this.feedbackText = "";
        this.closeModal();
      } catch (error) {

        if (error.response && error.response.status === 403) {
          this.feedbackText = "";
          this.closeModal();
          this.$emit("saveFeedbackResponse", error.response.data.message);
        } else {
          this.feedbackText = "";
          this.closeModal();
          this.$emit("saveFeedbackResponse");
          console.error("Error saving feedback:", error);
        }
      } finally {
        this.isLoading = false;
      }
    },

    closeModal() {
      this.feedbackText = "";
      this.isLoading = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 50%;
  max-width: 800px;
  height: auto;
  max-height: 90%;
  overflow-y: auto;
  text-align: center;
}

.modal-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.feedback-input {
  width: 100%;
  min-height: 120px;
  height: 150px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 15px;
}

.feedback-input:focus {
  outline: none;
}

.modal-actions button {
  margin: 5px;
  padding: 12px 24px;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button {
  background: #32aaed;
  color: white;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.save-button:disabled {
  background: #32aaed;
  color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

.cancel-button {
  background: #f44336;
  color: white;
}
</style>
